import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import writeAccessHintIllustrationUrl from './jump-to-app-illustration-write-access-hint.png';

const StyledTypography = styled(Typography)`
  margin-block-end: 1rem;
`;

const ImageScaledToWidth = styled.img`
  max-width: 70%;
  margin: 0 auto 1rem;
  display: block;
`;

const JumpToAppDialogContent: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledTypography>{t('jumpToApp.legalDisclaimer')}</StyledTypography>
      <StyledTypography>
        {t('jumpToApp.permissionsDescription')}
      </StyledTypography>
      {/* element is (aria-)hidden, no alt text needed */}
      {/* eslint-disable-next-line styled-components-a11y/alt-text*/}
      <ImageScaledToWidth src={writeAccessHintIllustrationUrl} aria-hidden />
      <StyledTypography>
        {/* eslint-disable-next-line no-secrets/no-secrets */}
        {t('jumpToApp.enableAutoGrantWritePermissions')}
      </StyledTypography>
    </>
  );
};

export default JumpToAppDialogContent;
