import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconRain from '../../../../../base/stihl-material-ui/icons/stihl-icon-rain';
import StihlIconSun from '../../../../../base/stihl-material-ui/icons/stihl-icon-sun';
import type {
  CardField as CardFieldValue,
  CardFieldRangeOption,
  CardFieldRangeValue,
} from '../../../model/card-field.model';
import { CardFieldType } from '../../../model/card-field.model';
import type { SettingsCard as SettingsCardValue } from '../../../model/card.model';
import type { Meta } from '../../../model/page.model';
import CardField from '../../card-field/card-field';
import {
  CardHeader,
  ContentWrapper,
  StyledCardContent,
} from '../generic-card/generic-card';
import JumpToAppButton from './jump-to-app/jump-to-app-button';
import JumpToAppDialogContainer from './jump-to-app/jump-to-app-dialog-container';

const rainSensorSensitivityValues: CardFieldRangeOption[] = [
  {
    value: 0,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.anyWeather',
  },
  {
    value: 0.25,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.veryWet',
  },
  {
    value: 0.5,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.wet',
  },
  {
    value: 0.75,
    label: 'deviceDetails.settings.rainSensorSensitivityValues.aLittleWet',
  },
  { value: 1, label: 'deviceDetails.settings.rainSensorSensitivityValues.dry' },
];

const useCardFieldValueMapping = (): ((
  field: CardFieldValue,
) => CardFieldValue) => {
  const { i18n } = useTranslation();

  const formatDistanceMillimeter = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'unit',
        unit: 'millimeter',
        maximumFractionDigits: 0,
      }),
    [i18n.language],
  );

  const formatNumberHours = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'unit',
        unit: 'hour',
      }),
    [i18n.language],
  );

  const formatNumberMinutes = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'unit',
        unit: 'minute',
      }),
    [i18n.language],
  );

  return useCallback(
    (field) => {
      switch (field.title) {
        case 'deviceDetails.settings.cuttingHeight': {
          const value = formatDistanceMillimeter.format(
            (field.value as number) * 1000,
          );
          const type = CardFieldType.Text;
          return { ...field, value, type };
        }
        case 'deviceDetails.settings.defaultManualMowingDuration': {
          const hours = (field.value as number) / 3600;
          const fullHours = Math.floor(hours);
          const minutes = Math.round(
            ((field.value as number) - fullHours * 3600) / 60,
          );

          const value = `${formatNumberHours.format(hours)} ${formatNumberMinutes.format(minutes)}`;

          const type = CardFieldType.Text;
          return { ...field, value, type };
        }
        case 'deviceDetails.settings.rainSensorSensitivity': {
          const value: CardFieldRangeValue = {
            currentValue: field.value as number,
            rangeOptions: rainSensorSensitivityValues,
            minIcon: <StihlIconRain />,
            maxIcon: <StihlIconSun />,
          };
          const type = CardFieldType.Range;
          return { ...field, value, type };
        }
        default: {
          return field;
        }
      }
    },
    [formatDistanceMillimeter, formatNumberHours, formatNumberMinutes],
  );
};

type SettingsCardProps = {
  cardData: SettingsCardValue;
  meta: Meta;
};

const SettingsCard: FC<SettingsCardProps> = ({ meta, cardData }) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  function handleModalOpen(): void {
    if (!meta.hasMyiMowAppAccess) return;
    setModalOpen(true);
  }

  function handleClose(): void {
    setModalOpen(false);
  }

  const getMappedData = useCardFieldValueMapping();

  return cardData.cardFields.length > 0 ? (
    <MuiCard data-testid="settingsCard">
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
        <JumpToAppButton
          onClick={handleModalOpen}
          customerAppAccess={cardData.customerAppAccess}
        />
        <JumpToAppDialogContainer
          open={isModalOpen}
          onClose={handleClose}
          deviceId={meta.deviceId}
          deviceModel={meta.deviceModel}
        />
      </CardHeader>
      <StyledCardContent>
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>
            <CardField value={getMappedData(data)} />
          </ContentWrapper>
        ))}
      </StyledCardContent>
    </MuiCard>
  ) : (
    <> </>
  );
};

export default SettingsCard;
